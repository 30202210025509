/******************************
            General
*******************************/

.main.container {
    margin-top: 7em;
}

.text-area {
    width: 100%;
}


/******************************
             Custom
*******************************/

.ui.centered.button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/******************************
           Login page
*******************************/

.ui.login.grid {
    background: url("../images/login-backround.jpg")/* image */
    center / cover/* position / size */
    no-repeat/* repeat */
    fixed/* attachment */
    padding-box/* origin */
    content-box/* clip */
    grey;
    height: 100vh;
}

.ui.login.grid>.column {
    width: 32em;
}

.ui.login.grid .logo.image {
    width: 100px;
    margin-bottom: 2em;
}

.ui.login.grid .button[type=submit] {
    padding-top: 0.714rem !important;
    padding-bottom: 0.714rem !important;
}


/******************************
           Main Menu
*******************************/

.ui.menu .user.menu {
    background-color: #6c3667;
}

.ui.menu .user.menu>.dropdown.item {
    color: #fff!important;
}


/******************************
        Datatable overrides
*******************************/


/*remove the search input from the dataTables until we find a better way to search through data driven dataTables*/

table.dataTable {
    width: 100%!important;
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    background: none transparent;
    box-shadow: none;
    border: none;
    border-radius: 0;
    top: -2em;
    left: 0;
    width: 100%;
    /*height: 100%!important;*/
    height: calc(100% + 2em);
    margin-left: 0;
}

#crawlingQuesDataTable_filter,
#agencyRequest_filter {
    display: none;
}

/************************************
        Toastr custom style
*************************************/

#toast-container {
    z-index: 899;
}

#toast-container > div {
    width: 400px;
}

.toast-close-button {
    right: 0;
    top: 0;
    margin-top: -2px;
}

.toast-custom-position {
    top: 5em;
    right: 0px;
}

#toast-container .notification.button {
    background: #106510;
    color: #fff;
    margin-right: .5em;
    margin-left: 0;
}